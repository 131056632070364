<script setup>
import { computed, onBeforeMount, reactive, watch} from 'vue'
import { useStore } from 'vuex'
import { EMAIL_REGEX, hasBadWords } from '../js/constants'
import {
  internalZipLookup,
  ipqsCheckIfPhoneIsValid,
  ipqsIsEmailInvalid,
  isNameGibberish,
  extractUrlParams,
  checkForPlaceholder,
  externalDescriptionValidation
} from '../js/utils'
import SmsVerificationModal from './SmsVerificationModal'

const assignPrePopFields = () => {
  //first extract url parameters
  const urlParams = extractUrlParams()

  if (urlParams['fname']) {
    state.inputValues.firstName = checkForPlaceholder(urlParams['fname'])
  }

  if (urlParams['lname']) {
    state.inputValues.lastName = checkForPlaceholder(urlParams['lname'])
  }

  if (urlParams['email']) {
    state.inputValues.emailVal = checkForPlaceholder(urlParams['email'])
  }

  if (urlParams['zip']) {
    let parsedZip = parseInt(urlParams['zip'])
    if (!isNaN(parsedZip)) {
      state.inputValues.zipVal = checkForPlaceholder(urlParams['zip'].toString())
    }
  }
}

const store = useStore()
const state = reactive({
      buttonText: 'GET A FREE CONSULTATION',
      inputValues: {
        firstName: '',
        lastName: '',
        emailVal: '',
        phoneVal: '',
        zipVal: '',
        debtType: 'Debt Type',
        debtAmount: 'Debt Amount',
        monthlyIncome: '',
        ownRealEstate: '',
        valueOfAssets: '',
        caseDescription: '',
        enoughWords: 'yes',
        badWords: 'no',
        isGibberish: 'no'
      },
      values: {
        city: '',
        state: '',
        county: '',
      },
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phoneError: computed(() => store.state.phoneError),
      zipError: false,
      debtTypeError: false,
      debtAmountError: false,
      showModal: computed(() => store.state.twilioSmsVerify.toggleModalWithSmsVerification),
      phoneCodeIsApproved: computed(() => store.state.twilioSmsVerify.approved),
      phoneErrorMsg: 'Please enter your phone number.'
    });

onBeforeMount(() => {
  store.dispatch('init')
  console.log('test')
  assignPrePopFields()
})

watch(() => [state.inputValues.firstName],() => {
  state.firstNameError = !state.inputValues.firstName.length > 0;
})
watch(() => [state.inputValues.lastName],() => {
  state.lastNameError = !state.inputValues.lastName.length > 0;
})
watch(() => [state.inputValues.emailVal],() => {
  state.emailError = !state.inputValues.emailVal.length > 0;
})
watch(() => [state.inputValues.phoneVal],() => {
  if (state.inputValues.phoneVal.length > 0) {
    store.commit('twilioSmsVerify/RESET_TWILIO')
    store.commit('SET_PHONE_ERROR', false)
  }
})
watch(() => [state.inputValues.zipVal], () => {
  state.zipError = !state.inputValues.zipVal.length > 0;
})
watch(() => state.inputValues.debtAmount, () => {
  state.debtAmountError = !(state.inputValues.debtAmount !== 'Debt Amount')
})
watch(() => state.inputValues.debtType, () => {
  state.debtTypeError = !(state.inputValues.debtType !== 'Debt Type')
})

watch(() => state.phoneCodeIsApproved, () => {
  if (!state.firstNameError &&
    !state.lastNameError &&
    !state.emailError &&
    !state.phoneError &&
    !state.zipError &&
    !state.debtTypeError &&
    !state.debtAmountError &&
    state.phoneCodeIsApproved) {
    submitData()
  }
})

function firstNameValidation() {
  return state.firstNameError = state.inputValues.firstName.length === 0;
}

function lastNameValidation() {
  return state.lastNameError = state.inputValues.lastName.length === 0;
}

async function emailValidation() {
  //check if email has proper form using regex
  const validFormat = EMAIL_REGEX.test(String(state.inputValues.emailVal).toLowerCase())

  if (validFormat) {
    return state.emailError = await ipqsIsEmailInvalid(state.inputValues.emailVal)
  }

  return state.emailError = true
}

async function phoneValidation() {
  if (!state.inputValues.phoneVal || state.inputValues.phoneVal.length < 14) {
    store.commit('SET_PHONE_ERROR', true)
    state.phoneErrorMsg = 'Please enter your phone number.'
    return
  }

  const { valid, fullValid } = await ipqsCheckIfPhoneIsValid(state.inputValues.phoneVal);

  if (!fullValid) {
    store.commit('SET_PHONE_ERROR', true)
    state.phoneErrorMsg = 'Please enter a valid phone number.'
  }

  if (window.location.pathname.includes('/v2') && !state.phoneCodeIsApproved && valid) {
    showTwilioPhoneModal()
  }
}

async function zipValidation() {
  if (!state.inputValues.zipVal || state.inputValues.zipVal.length < 5) {
    state.zipError = true
    return
  }

  const zipData = await internalZipLookup(state.inputValues.zipVal)
  state.zipError = !zipData.zip;

  if (!state.zipError) {
    state.values.city = zipData.city
    state.values.state = zipData.state
    state.values.county = zipData.county
  }
}

const showTwilioPhoneModal = () => {
  store.commit('SET_PHONE_ERROR', true)
  state.phoneErrorMsg = 'Please verify your phone number.'

  document.body.classList.add('modal-open')
  store.commit('twilioSmsVerify/TOGGLE_MODAL_WITH_SMS_VERIFICATION')

  if (state.showModal) {
    store.dispatch('twilioSmsVerify/postPhone', state.inputValues.phoneVal)
  }
}

function debtAmountValidation() {
  return state.debtAmountError = state.inputValues.debtAmount === 'Debt Amount'
}

function debtTypeValidation() {
  return state.debtTypeError = state.inputValues.debtType === 'Debt Type'
}

async function descriptionValidation() {
  if (!state.inputValues.caseDescription) return

  const response = await externalDescriptionValidation(state.inputValues.caseDescription)

  state.inputValues.enoughWords = response.enoughWords
  state.inputValues.badWords = response.hasBadWords
  state.inputValues.isGibberish = response.isGibberish
}

async function checkAllValidations() {
  firstNameValidation()
  lastNameValidation()
  debtAmountValidation()
  debtTypeValidation()

  await Promise.all([
    emailValidation(),
    phoneValidation(),
    zipValidation(),
    descriptionValidation()
  ])

  return !state.firstNameError &&
      !state.lastNameError &&
      !state.emailError &&
      !state.phoneError &&
      !state.zipError &&
      !state.debtTypeError &&
      !state.debtAmountError;
}

function scrollToFirstError() {
  const el = document.querySelector(`.alert-border`);
  if (el === null) return
  window.scrollTo({
    top: el.offsetTop + 325,
    behavior: "smooth"
  })
}

async function submitData() {
      state.buttonText = 'LOADING...';

      const checkForErrors = await checkAllValidations()

      scrollToFirstError();

  if (checkForErrors) {
    //name gibberish
    let nameGibberish = isNameGibberish(state.inputValues.firstName) ? 'yes' : 'no'
    if (nameGibberish === 'no') {
      nameGibberish = isNameGibberish(state.inputValues.lastName) ? 'yes' : 'no'
    }

    //test if name has bad words
    let nameBadWord = hasBadWords().test(state.inputValues.firstName) ? 'yes' : 'no'
    if (nameBadWord === 'no') {
      nameBadWord = hasBadWords().test(state.inputValues.lastName) ? 'yes' : 'no'
    }

    store.commit('SET_ARRAY_OF_FIELDS', [
      { field: 'first_name', value: state.inputValues.firstName },
      { field: 'last_name', value: state.inputValues.lastName },
      { field: 'name_gibberish', value: nameGibberish },
      { field: 'name_bad_word', value: nameBadWord },
      { field: 'phone', value: state.inputValues.phoneVal.replace(/[()\-\s]+/g, '') },
      { field: 'email', value: state.inputValues.emailVal },
      { field: 'zip_code', value: state.inputValues.zipVal },
      { field: 'city', value: state.values.city },
      { field: 'state', value: state.values.state },
      { field: 'county', value: state.values.county },
      { field: 'debt_type', value: state.inputValues.debtType },
      { field: 'debt_amount', value: state.inputValues.debtAmount },
      { field: 'monthly_income', value: state.inputValues.monthlyIncome },
      { field: 'own_real_estate', value: state.inputValues.ownRealEstate },
      { field: 'value_of_assets', value: state.inputValues.valueOfAssets },
      { field: 'case_description', value: state.inputValues.caseDescription },
      { field: 'case_description_filled', value: state.inputValues.caseDescription ? 'yes' : 'no' },
      { field: 'is_gibberish', value: state.inputValues.isGibberish },
      { field: 'has_bad_words', value: state.inputValues.badWords },
      { field: 'enough_words', value: state.inputValues.enoughWords }
    ])

    await store.dispatch('postData')

  } else {
    state.buttonText = 'GET A FREE CONSULTATION';
  }
}
</script>

<template>
  <div class="form-section">
    <SmsVerificationModal v-if="state.showModal" />
    <div class="background-image">
      <div class="overlay"></div>
      <div class="welcome-title">
        <h1>YOU COULD BECOME DEBT FREE</h1>
        <h2>in as little as 12-48 Months!</h2>
      </div>
      <div class="form-container">
        <div class="list-container desktop">
        <ul>
          <li>
            <img src="../assets/images/check-arrow.png" alt="check symbol" />
            Free Consultation
          </li>
          <li>
            <img src="../assets/images/check-arrow.png" alt="check symbol" />
            See How You Could Settle Your Debt Without Bankruptcy
          </li>
          <li>
            <img src="../assets/images/check-arrow.png" alt="check symbol" />
            Get a Free Savings Estimate
          </li>
          <li>
            <img src="../assets/images/check-arrow.png" alt="check symbol" />
            No Obligation
          </li>
        </ul>
      </div>
        <div class="form-box">
          <div
              v-if="state.buttonText === 'LOADING...'"
              class="disabled-overlay"
          >
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
          <div class="container">
            <div class="row">
              <div class="box">
                <h3>DO I QUALIFY FOR DEBT RELIEF?</h3>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="box">
                <div class="name-container">
                  <div class="name-box">
                    <input
                      v-model.trim="state.inputValues.firstName"
                      :class="{'alert-border': state.firstNameError}"
                      :disabled="state.buttonText === 'LOADING...'"
                      class="input-style"
                      type="text"
                      placeholder="First Name"
                    />
                    <p
                      :style="[state.firstNameError ? {'opacity': '1'} : {'opacity': '0'}]"
                      class="error-msg"
                    >
                      Please enter your first name.
                    </p>
                  </div>
                  <div class="name-box">
                    <input
                      v-model.trim="state.inputValues.lastName"
                      :class="{'alert-border': state.lastNameError}"
                      :disabled="state.buttonText === 'LOADING...'"
                      class="input-style"
                      type="text"
                      placeholder="Last Name"
                    />
                    <p
                      :style="[state.lastNameError ? {'opacity': '1'} : {'opacity': '0'}]"
                      class="error-msg"
                    >
                      Please enter your last name.
                    </p>
                  </div>
                </div>
                <input
                  v-model="state.inputValues.emailVal"
                  :class="{'alert-border': state.emailError}"
                  :disabled="state.buttonText === 'LOADING...'"
                  class="input-style"
                  name="email"
                  type="email"
                  placeholder="Email"
                />
                <p
                  :style="[state.emailError ? {'opacity': '1'} : {'opacity': '0'}]"
                  class="error-msg"
                >
                  Please enter your email address.
                </p>
                <input
                  v-model="state.inputValues.phoneVal"
                  :class="{'alert-border': state.phoneError}"
                  :disabled="state.buttonText === 'LOADING...'"
                  v-maska="'(###) ###-####'"
                  class="input-style"
                  name="phone"
                  type="tel"
                  placeholder="Phone"
                />
                <p
                  :style="[state.phoneError ? {'opacity': '1'} : {'opacity': '0'}]"
                  class="error-msg"
                >
                  {{ state.phoneErrorMsg }}
                </p>
                <input
                  v-model="state.inputValues.zipVal"
                  :class="{'alert-border': state.zipError}"
                  :disabled="state.buttonText === 'LOADING...'"
                  v-maska="'#####'"
                  class="input-style"
                  name="zip"
                  type="tel"
                  placeholder="Zip"
                />
                <p
                  :style="[state.zipError ? {'opacity': '1'} : {'opacity': '0'}]"
                  class="error-msg"
                >
                  Please enter your zip code.
                </p>
                <div class="name-container">
                  <div class="name-box">
                    <select
                      v-model="state.inputValues.debtType"
                      :class="{'alert-border': state.debtTypeError}"
                      :disabled="state.buttonText === 'LOADING...'"
                      class="input-style select-style"
                      id="select"
                    >
                      <option value="Debt Type" disabled="disabled" selected>Debt Type</option>
                      <option value="Credit card">Credit card</option>
                      <option value="Tax debt">Tax debt</option>
                      <option value="Personal loan">Personal loan</option>
                    </select>
                    <p
                      :style="[state.debtTypeError ? {'opacity': '1'} : {'opacity': '0'}]"
                      class="error-msg"
                    >
                      Please select type.
                    </p>
                  </div>
                  <div class="name-box">
                    <select
                      v-model="state.inputValues.debtAmount"
                      :class="{'alert-border': state.debtAmountError}"
                      :disabled="state.buttonText === 'LOADING...'"
                      name="debt-amount"
                      class="input-style select-style"
                      id="select"
                    >
                      <option value="Debt Amount" disabled="disabled" selected>Debt Amount</option>
                      <option value="$0-$5,000">$0 - $5,000</option>
                      <option value="$5,000 - $9,999">$5,000 - $9,999</option>
                      <option value="$10,000 - $14,999">$10,000 - $14,999</option>
                      <option value="$15,000 - $19,999">$15,000 - $19,999</option>
                      <option value="$20,000 - $29,999">$20,000 - $29,999</option>
                      <option value="$30,000 - $39,999">$30,000 - $39,999</option>
                      <option value="$40,000 - $49,999">$40,000 - $49,999</option>
                      <option value="$50,000 - $59,999">$50,000 - $59,999</option>
                      <option value="$60,000 - $69,999">$60,000 - $69,999</option>
                      <option value="$70,000 - $79,999">$70,000 - $79,999</option>
                      <option value="$80,000 - $89,999">$80,000 - $89,999</option>
                      <option value="$90,000 - $99,999">$90,000 - $99,999</option>
                      <option value="$100,000 +">$100,000+</option>
                    </select>
                    <p
                      :style="[state.debtAmountError ? {'opacity': '1'} : {'opacity': '0'}]"
                      class="error-msg"
                    >
                      Please select amount.
                    </p>
                  </div>
                </div>

<!--                <select-->
<!--                  v-model="state.inputValues.monthlyIncome"-->
<!--                  :disabled="state.buttonText === 'LOADING...'"-->
<!--                  class="input-style select-style"-->
<!--                >-->
<!--                  <option value="" disabled="disabled" selected>Total Monthly Income</option>-->
<!--                  <option value="Less than $1k">Less than $1k</option>-->
<!--                  <option value="$1k - $5k">$1k - $5k</option>-->
<!--                  <option value="$5k - $10k">$5k - $10k</option>-->
<!--                  <option value="$10k - $15k">$10k - $15k</option>-->
<!--                  <option value="More than $15k">More than $15k</option>-->
<!--                </select>-->
<!--                <p class="error-msg"></p>-->

<!--                <select-->
<!--                  v-model="state.inputValues.ownRealEstate"-->
<!--                  :disabled="state.buttonText === 'LOADING...'"-->
<!--                  class="input-style select-style"-->
<!--                >-->
<!--                  <option value="" disabled="disabled" selected>Do you own real estate?</option>-->
<!--                  <option value="Yes">Yes</option>-->
<!--                  <option value="No">No</option>-->
<!--                </select>-->
<!--                <p class="error-msg"></p>-->

<!--                <select-->
<!--                  v-model="state.inputValues.valueOfAssets"-->
<!--                  :disabled="state.buttonText === 'LOADING...'"-->
<!--                  class="input-style select-style"-->
<!--                >-->
<!--                  <option value="" disabled="disabled" selected>Value of other assets</option>-->
<!--                  <option value="Less than $50k">Less than $50k</option>-->
<!--                  <option value="$50k-$500k">$50k - $500k</option>-->
<!--                  <option value="$500k-$1m">$500k - $1m</option>-->
<!--                  <option value="More than $1 million">More than $1 million</option>-->
<!--                </select>-->
<!--                <p class="error-msg"></p>-->

<!--                <textarea-->
<!--                  rows="5"-->
<!--                  placeholder="Case description (optional)"-->
<!--                  v-model="state.inputValues.caseDescription"-->
<!--                  :disabled="state.buttonText === 'LOADING...'"-->
<!--                ></textarea>-->

                <button
                    @click="submitData"
                    :disabled="state.buttonText === 'LOADING...'"
                    :style="[state.buttonText === 'LOADING...' ? {'cursor': 'not-allowed'} : {'cursor': 'pointer'}]"
                    class="submit-button"
                >
                  {{ state.buttonText }}
                </button>
                <label for="">
                  <input type="hidden" id="leadid_tcpa_disclosure"/>
                  <p class="toa">
                    By clicking the "Get A Free Consultation" button, you agree to
                    the <a onclick="window.open('/terms-and-conditions','','scrollbars=yes,width=600,height=400')">Terms & Conditions</a> and
                    <a onclick="window.open('/privacy-policy','','scrollbars=yes,width=600,height=400')">Privacy Policy</a> and authorize Debt
                    Reduction Team and its <a onclick="window.open('/partners','','scrollbars=yes,width=780,height=650')">partners</a> to contact you
                    by email or at the phone number you entered using automated
                    technology including recurring auto-dialers, pre-recorded
                    messages, and text messages, even if your phone is a mobile
                    number or is currently listed on any state, federal, or
                    corporate "Do Not Call" list, and you are not required to give
                    your consent as a condition of service. You understand that
                    your telephone company may impose charges on you for these
                    contacts, and that you can revoke this consent at any time.
                    For SMS campaigns Text STOP to cancel and HELP for help.
                    Message and data rates may apply. Consent is not required as a
                    condition to utilize debtreductionteam.com services and you are under no
                    obligation to purchase anything.
                  </p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="list-container mobile">
          <ul>
            <li>
              <img src="../assets/images/check-arrow.png" alt="check symbol" />
              Free Consultation
            </li>
            <li>
              <img src="../assets/images/check-arrow.png" alt="check symbol" />
              See How You Could Settle Your Debt Without Bankruptcy
            </li>
            <li>
              <img src="../assets/images/check-arrow.png" alt="check symbol" />
              Get a Free Savings Estimate
            </li>
            <li>
              <img src="../assets/images/check-arrow.png" alt="check symbol" />
              No Obligation
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 37%);
  height: 100%;
  width: 100%;
}
.form-section {
  width: 100%;
  background: rgb(208, 212, 215);
  background: linear-gradient(
    90deg,
    rgba(208, 212, 215, 1) 0%,
    rgba(217, 221, 223, 1) 100%
  );
  .background-image {
    background: url("../assets/images/young-people.jpeg") center no-repeat #d9dddf;
    height: 100%;
    background-size: cover;
    position: relative;
    .welcome-title {
      position: relative;
      font-family: "librefranklin-extrabold", sans-serif;
      color: #fff;
      text-align: center;
      max-width: 1200px;
      margin: auto;
      padding: 20px;
      box-sizing: border-box;
      text-shadow: 1px 1px black;
      h1 {
        font-size: 40px;
        line-height: 64px;
        font-weight: bold;
        font-variant: normal;
        text-transform: uppercase;
      }
      h2 {
        font-size: 36px;
        line-height: 40px;
        font-weight: normal;
      }
    }
    .form-container {
      padding: 0 20px;
      max-width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-evenly;
      box-sizing: border-box;
      position: relative;
      .list-container {
        height: auto;
        text-align: left;
        ul {
          padding: 20px;
          list-style-type: none;
          background-color: #0000006b;
          border-radius: 10px;
          li {
            height: auto;
            font-size: 20px;
            color: #f5f6f6;
            font-family: "Libre Franklin", sans-serif;
            padding-bottom: 9px;
            text-shadow: 0px 0px 5px #000000;
            &:last-child {
              padding-bottom: 0;
            }
            img {
              margin-right: 5px;
              max-height: 21px;
            }
          }
        }

        &.mobile {
          display: none;
        }
      }
      .form-box {
        width: 40%;
        max-width: 470px;
        background-color: #f5f6f6;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        padding: 20px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        margin-bottom: -50px;
        .container .row .box {
          .name-container {
            display: flex;
            justify-content: space-between;
            max-width: 430px;
            .name-box {
              width: 48%;
              display: grid;
              input {
                width: calc(100% - 32px);
              }
            }
          }
          display: flex;
          flex-direction: column;
          h3 {
            font-family: "librefranklin-extrabold", sans-serif;
            font-size: 30px;
            line-height: 36px;
            color: #72ccbf;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 20px;
            text-shadow: 1px 1px #256483;
          }
          .input-style {
            height: 30px;
            float: left;
            font-size: 18px;
            line-height: 30px;
            color: #666666;
            font-weight: normal;
            font-family: "Libre Franklin", sans-serif;
            text-transform: none;
            background-color: #fff;
            border: 1px solid #cccccc;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            padding: 9px 15px;
            text-overflow: ellipsis;
            &.select-style {
              height: 50px;
              color: #666666;
            }
            &:focus {
              outline: none;
            }
            &.alert-border {
              border: 1px #ff0000 solid;
              animation: blink 1s;
              animation-iteration-count: 3;
              background-color: #f8cdcd;
            }
          }
          .error-msg {
            font-family: "Libre Franklin", sans-serif;
            font-size: 12px;
            color: red;
            margin: 2px 0 5px 10px;
            opacity: 0;
            min-height: 14px;
          }

          textarea {
            resize: vertical;
            color: #666666;
            border: 1px solid #cccccc;
            font-weight: normal;
            font-family: "Libre Franklin", sans-serif;
            font-size: 18px;
            border-radius: 5px;
            padding: 9px 15px;
            margin-bottom: 7px;
          }

          .submit-button {
            background: #1eabb8;
            text-align: center;
            float: left;
            padding: 10px 20px;
            width: 100%;
            height: auto;
            text-decoration: none;
            font-size: 20px;
            line-height: 26px;
            color: #fff;
            font-weight: bold;
            font-family: "Libre Franklin", sans-serif;
            text-transform: uppercase;
            margin-top: 8px;
            margin-bottom: 15px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;
            border: none;
            &:hover {
              background: #169eaa;
            }
          }
          .toa {
            font-size: 12pt;
            line-height: 14px;
            color: black;
            text-align: justify;
            font-family: "Libre Franklin", sans-serif;
            a {
              color: #1eabb8;
              cursor: pointer;
            }
          }
        }
        .disabled-overlay {
          position: absolute;
          background-color:rgba(255, 255, 255, 0.6);
          width: 100%;
          height: 100%;
          z-index: 20;
          cursor: wait;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          left: 0;
          .lds-roller {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
          }
          .lds-roller div {
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            transform-origin: 40px 40px;
            &:after {
              content: " ";
              display: block;
              position: absolute;
              width: 9px;
              height: 9px;
              border-radius: 50%;
              background: #1eabb8;
              margin: -4px 0 0 -4px;
            }
            &:nth-child(1) {
              animation-delay: -0.036s;
              &:after {
                top: 63px;
                left: 63px;
              }
            }
            &:nth-child(2) {
              animation-delay: -0.072s;
              &:after {
                top: 68px;
                left: 56px;
              }
            }
            &:nth-child(3) {
              animation-delay: -0.108s;
              &:after {
                top: 71px;
                left: 48px;
              }
            }
            &:nth-child(4) {
              animation-delay: -0.144s;
              &:after {
                top: 72px;
                left: 40px;
              }
            }
            &:nth-child(5) {
              animation-delay: -0.18s;
              &:after {
                top: 71px;
                left: 32px;
              }
            }
            &:nth-child(6) {
              animation-delay: -0.216s;
              &:after {
                top: 68px;
                left: 24px;
              }
            }
            &:nth-child(7) {
              animation-delay: -0.252s;
              &:after {
                top: 63px;
                left: 17px;
              }
            }
            &:nth-child(8) {
              animation-delay: -0.288s;
              &:after {
                top: 56px;
                left: 12px;
              }
            }
          }

          @keyframes lds-roller {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .form-section .background-image {
    .form-container {
      display: block;
      padding-bottom: 30px;
      .list-container {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
          margin: 20px auto 0 auto;
          max-width: 470px;
        }
        width: 100%;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        ul {
          margin: auto;
          list-style-type: none;
          max-width: 430px;
        }
      }
      .form-box {
        width: 100%;
        margin: auto;
      }
    }
  }
}

@media (max-width: 500px) {
  .form-section .background-image {
    .welcome-title {
      padding: 10px 20px;
      h1 {
        font-size: 28px;
        line-height: 28px;
      }

      h2 {
        font-size: 22px;
      }
    }
    .form-container .list-container {
      ul li {
        font-size: 20px;
      }
    }
    .form-container .form-box .container .row .box .name-container {
      display: block;
      .name-box {
        width: 100%;
      }
    }
  }
}

@keyframes blink { 50% { border-color:#fff ; }  }
</style>
