<template>
  <div class="footer-container">
    <div class="footer-nav">
      <a href="/california-policy" target="_blank">California Privacy</a>
      <div class="space">&nbsp;|&nbsp;</div>
      <a href="/privacy-policy" target="_blank">Privacy Policy </a>
      <div class="space">&nbsp;|&nbsp;</div>
      <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a>
    </div>
    <p>
      DebtReductionTeam is a premier service focused on providing customers an
      opportunity to connect with some of the nations top debt consolidation,
      negotiation, and settlement companies. If you have any questions regarding
      this service please feel free to contact one of our representatives at our
      toll free number.
    </p>
  </div>
</template>

<style scoped lang="scss">
.footer-container {
  max-width: 1200px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  font-family: "Libre Franklin", sans-serif;
  border-top: 1px solid #cccccc;
  padding: 26px 10px 24px 10px;
  margin: auto;
  .footer-nav {
    display: inline-flex;
    margin: 0 0 11px 0;
    a {
      color: #1eabb8;
      text-decoration: none;
      &:hover {
        color: #1296a2;
      }
    }
  }
  p {
    text-align: center;
  }
}

@media (max-width: 500px) {

  .footer-container {
    .footer-nav {
      a {
        max-width: 80px;
      }
      .space {
        display: none;
      }
    }
    p {
      text-align: center;
    }
  }
}
</style>
