export const debtReliefFlowV3 = {
    namespaced: true,
    actions: {
        goToNextStep({rootState, getters, commit, dispatch}, step) {
            let nextStep = ''
            let progress = 0
            // const urlSearchParams = new URLSearchParams(location.search)

            switch(step) {
                case 'StartPath': {
                    // if(urlSearchParams.has('affid') && urlSearchParams.get('affid') === '21') {
                    //     commit('SET_FIELD', { field: 'debt_type', value: 'Credit card' }, {root: true})
                    //     nextStep = 'DebtReliefQuestionDebtAmount'
                    //     progress = 33
                    //     break
                    // }
                    nextStep = 'DebtReliefQuestionDebtType'
                    progress = 25
                    break
                }

                case 'DebtReliefQuestionDebtType': {
                    nextStep = 'DebtReliefQuestionDebtAmount'
                    progress = 50
                    break
                }

                case 'DebtReliefQuestionDebtAmount': {
                    nextStep = 'DebtReliefQuestionNameAndEmail'
                    // if(urlSearchParams.has('affid') && urlSearchParams.get('affid') === '21') {
                    //     progress = 66
                    //     break
                    // }
                    progress = 75
                    break
                }

                case 'DebtReliefQuestionNameAndEmail': {
                    nextStep = 'DebtReliefQuestionPhoneAndZip'
                    progress = 100
                    break
                }

                case 'DebtReliefQuestionPhoneAndZip': {
                    dispatch('postData', null, { root: true })
                    return
                }
            }

            commit('SET_PROGRESS', progress, {root: true})
            commit('SET_CURRENT_STEP', nextStep, {root: true})
        }
    }
}