import { createStore } from 'vuex'
import router from '../router'
import axios from 'axios'
import twilioSmsVerify from './modules/twilio-sms-verify'
import { debtReliefFlowV3 } from '@/store/modules/debt-relief-flow-v3'
import { debtReliefFlowV4 } from '@/store/modules/debt-relief-flow-v4'
import {
  extractAllUrlParamsAndSetThemInVuexStore,
  getIpAddress,
  getJornayaLeadIdToken,
  getLeadSource,
  getTrustedFormByPromise,
  getUserAgent,
  setEverFlow
} from '../js/utils'

export default createStore({
    modules: {
        debtReliefFlowV3,
        debtReliefFlowV4,
        twilioSmsVerify
    },
    state: {
        fields: {},
        currentStep: null,
        phoneError: false,
        minHeightOfTheLastComponent: 0,
        isLoading: false,
        progress: 0
    },
    getters: {
        getFields(state) {
            return state.fields
        }
    },
    mutations: {
        SET_CURRENT_STEP( state, step ) {
            state.currentStep = step
        },
        SET_FIELD(state, { field, value }) {
            state.fields[field] = value
        },
        SET_ARRAY_OF_FIELDS(state, array) {
            array.forEach(object => {
                state.fields[object.field] = object.value
            })
        },
        SET_PHONE_ERROR(state, value) {
          state.phoneError = value
        },
        SET_CAMPAIGN(state, { campaignId, supplierId, key }) {
            state.fields.lp_campaign_id = campaignId
            state.fields.lp_supplier_id = supplierId
            state.fields.lp_key = key
        },
        SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT(state, value) {
            state.minHeightOfTheLastComponent = value
        },
        SET_IS_LOADING(state, value) {
            state.isLoading = value
        },
        SET_PROGRESS(state, value) {
            state.progress = value
        }
    },
    actions: {
        init({ commit }) {
            //set everflow as a field
            setEverFlow()
              .then(({ ef_transaction_id }) => {
                  commit('SET_FIELD', { field: 'ef_transaction_id', value: ef_transaction_id })
              })

            //get ip address and set as field in store
            getIpAddress()
                .then((ip) => {
                    commit('SET_FIELD', { field: 'ip_address', value: ip })
                })

            // Get Jornaya LeadId
            getJornayaLeadIdToken()
                .then(leadid_token => {
                    commit('SET_FIELD', { field: 'jornaya_id', value: leadid_token})
                })

            // Get Trusted Form
            getTrustedFormByPromise()
                .then(({ trustedFormId, trustedFormUrl }) => {
                    const trustedForm = [
                        { field: 'trusted_form_cert_id', value: trustedFormId },
                        { field: 'trusted_form_cert_url', value: trustedFormUrl }
                    ]
                    commit('SET_ARRAY_OF_FIELDS', trustedForm)
                })

            // Extract URL values and save them to Vuex Store
            commit('SET_ARRAY_OF_FIELDS', extractAllUrlParamsAndSetThemInVuexStore())

            //get user agent
            try {
                commit('SET_FIELD', { field: 'user_agent', value: getUserAgent() })
            } catch (e) {
                console.log('error getting user agent', e)
            }

            //get lead source: facebook / google
            commit('SET_FIELD', { field: 'lp_s2', value: getLeadSource() })
        },

        postData({ rootState }) {
            const fieldsSaved = rootState.fields

            //Modify data before post
            const data = {
                // lp_action,
                ...fieldsSaved,
                sub_id: fieldsSaved.sub_id == null ? '1' : fieldsSaved.sub_id,
                sub_id2: fieldsSaved.sub_id2 == null ? '' : fieldsSaved.sub_id2,
                sub_id3: fieldsSaved.sub_id3 == null ? '' : fieldsSaved.sub_id3,
                sub_id4: fieldsSaved.sub_id4 == null ? '' : fieldsSaved.sub_id4,
                affid: fieldsSaved.affid == null ? '1' : fieldsSaved.affid
            }

            // console.log('Posted data: ', data)
            Promise.allSettled([
                axios.post('https://control.org31415.dev/api/leads/ingest', data)
            ]).then(() => {
                router.push({path: 'thank-you'})
            }).catch(e => {
                console.log('error:', e)
            });
        }
    },
})
