export const debtReliefFlowV4 = {
    namespaced: true,
    actions: {
        goToNextStep({rootState, getters, commit, dispatch}, step) {
            let nextStep = ''
            let progress = 0

            switch(step) {
                case 'StartPath': {
                    nextStep = 'DebtReliefQuestionSelectDebtType'
                    progress = 25
                    break
                }

                case 'DebtReliefQuestionSelectDebtType': {
                    nextStep = 'DebtReliefQuestionSelectDebtAmount'
                    progress = 50
                    break
                }

                case 'DebtReliefQuestionSelectDebtAmount': {
                    nextStep = 'DebtReliefQuestionNameAndEmail'
                    progress = 75
                    break
                }

                case 'DebtReliefQuestionNameAndEmail': {
                    nextStep = 'DebtReliefQuestionPhoneAndZip'
                    progress = 100
                    break
                }

                case 'DebtReliefQuestionPhoneAndZip': {
                    dispatch('postData', null, { root: true })
                    return
                }
            }

            commit('SET_PROGRESS', progress, {root: true})
            commit('SET_CURRENT_STEP', nextStep, {root: true})
        }
    }
}