import axios from 'axios'
import { blacklist } from '@/js/blacklist'

const PATTERN_BLACK_LIST = new RegExp(`(^|\\W)(${blacklist.join('|')})($|\\W)`, 'gmi')

export function extractUrlParams() {
    const urlSearchParams = new URLSearchParams(location.search)

    const obj = {}

    urlSearchParams.forEach((value, key) => {
        switch (key) {
            case 's1':
                obj['sub_id'] = checkForPlaceholder(value) || '1'
                break
            case 'sub2':
                obj['sub_id2'] = checkForPlaceholder(value)
                break
            case 'sub3':
                obj['sub_id3'] = checkForPlaceholder(value)
                break
            case 'sub4':
                obj['sub_id4'] = checkForPlaceholder(value)
                break
            default:
                obj[key] = checkForPlaceholder(value)
        }
    })

    if ('sub1' in obj) {
        obj['sub_id'] = checkForPlaceholder(obj['sub1']) || '1'
    }

    if ('sub5' in obj) {
        obj['click_id'] = checkForPlaceholder(obj['sub5']) || ''
        delete obj.sub5
    }

    return obj;
}

export function extractAllUrlParamsAndSetThemInVuexStore() {

    const paramsFromUrl = extractUrlParams()
    let oid = '37'
    let ef_transaction_id = ''

    if ('_ef_transaction_id' in paramsFromUrl) {
        //reassign key to new and delete old key
        ef_transaction_id = paramsFromUrl._ef_transaction_id
        delete paramsFromUrl._ef_transaction_id
    }

    if ('oid' in paramsFromUrl) {
        oid = paramsFromUrl.oid
    }

    const setAnswersFormUrl = [
        { field: 'oid', value: oid },
        { field: 'ef_transaction_id', value: ef_transaction_id }
    ]

    //get any params from the url and set them as fields in vuex store
    for (const [key, value] of Object.entries(paramsFromUrl)) {
        setAnswersFormUrl.push({ field: key, value: value })
    }

    return setAnswersFormUrl
}

export const getLeadSource = () => {
    const r = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1][Math.floor(Math.random() * 20)]
    return ['google', 'facebook'][r]
}

//check for placeholder from url params, i.e {first_name} is a placeholder, John (without curly brackets) would be okay
export const checkForPlaceholder = text => /^{.+}$/g.test(text) ? '' : text

export const getIpAddress = async () => {
    let ip = '';
    try {
        const r = await fetch("https://api.ipify.org?format=json")
        const response = await r.json()
        ip = response.ip
    } catch (e) {
        const r = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
        const response = await r.text()
        const ipArray = response.match(/ip=([^\n]+)/)
        if (ipArray && ipArray.length > 1) {
            ip = ipArray[1]
        }
    }
    return ip
}

export const getUserAgent = () => {
    return navigator.userAgent
}

export const getJornayaLeadIdToken = () => {
    return new Promise(res => {
        const i = setInterval(() => {
            const input = document.getElementById("leadid_token")
            let leadid_token = ''
            if (input) {
                leadid_token = input.value
            }
            if (leadid_token) {
                clearInterval(i)
                res(leadid_token)
            }
        }, 50)
    })
}

//TODO: Combine the two functions of trusted form
export const getTrustedForm = () => {
    let trustedFormUrl = '';
    let trustedFormId = '';
    if (document.getElementById('xxTrustedFormCertUrl_0')) {
        const tfCertUrl = document.getElementById('xxTrustedFormCertUrl_0').value
        const tfParts = tfCertUrl.split("https://cert.trustedform.com/");
        trustedFormUrl = tfCertUrl;
        trustedFormId = tfParts[1];
    }
    return {
        trustedFormUrl,
        trustedFormId
    }
}

export const getTrustedFormByPromise = () => {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            const trustedForm = getTrustedForm()
            if (trustedForm.trustedFormUrl.length > 0) {
                clearInterval(interval)
                resolve(trustedForm)
            }
        }, 5)
    })
}

export const ipqsIsEmailInvalid = async (email) => {
  let ipqsUrl = 'https://validations.org31415.dev/api/validations/ipqs/email'
  let response = false

  try {
    const respFromIpqs = await axios.post(ipqsUrl, {
      email
    })
    response = !respFromIpqs.data.valid || respFromIpqs.data.disposable || respFromIpqs.data.fraud_score > 90
  } catch (err) {
    console.log(err)
    return response
  }

  return response
}
export const internalZipLookup = async (zipCode) => {
    return await axios
        .get(`https://zip.org31415.dev/us/${zipCode}`)
        .then(response => {
            return {
                city: response.data.city,
                state: response.data.state,
                state_long: response.data.state_long,
                county: response.data.county,
                zip: response.data.zip,
            }
        })
        .catch(e => {
            console.log('zipLookup error:', e)
            return {
                city: '',
                state: '',
                county: '',
                state_long: '',
                zip: '',
            }
        })
}

export async function ipqsCheckIfPhoneIsValid(phone) {
    //ipqs phone validation only accepts formatted numbers with country code in front
    const formattedNumber = phone.replace(/[()\-\s]+/g, "")
    const ipqsUrl = 'https://validations.org31415.dev/api/validations/ipqs/phone'

    const response = {
      valid: false,
      fullValid: false
    }

    try {
      const responseFromIpqs = await axios.post(ipqsUrl, {
        phone: formattedNumber
      })
      response.valid = responseFromIpqs.data.valid
      response.fullValid = responseFromIpqs.data.valid || responseFromIpqs.data.fraud_score > 85 || responseFromIpqs.data.line_type === 'Toll Free';
    } catch (err) {
      console.log(err)
      return response
    }

  return response
}

export const setEverFlow = () => {
    return new Promise(resolve => {
        const interval = setInterval(() => {
            if (window.EF) {
                const urlParams = new URLSearchParams(window.location.search)
                const tid = urlParams.get('_ef_transaction_id') || urlParams.get('ef_transaction_id')

                if (tid) {
                    urlParams.set('_ef_transaction_id', tid)
                    history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
                    resolve({ ef_transaction_id : tid })
                    clearInterval(interval)
                    return
                }

                EF.click({
                    offer_id: EF.urlParameter('oid') || 37,
                    affiliate_id: EF.urlParameter('affid') || 1,
                    transaction_id: EF.urlParameter('_ef_transaction_id'),
                }).then(tid => {
                    if (tid) {
                        urlParams.set('_ef_transaction_id', tid)
                        history.replaceState({}, '', location.origin + location.pathname + '?' + urlParams.toString())
                        resolve({ ef_transaction_id : tid })
                    }
                })
                clearInterval(interval)
            }
        }, 5)
    })
}

export const getRetreaverNumber = () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (window.RetreaverNumber) {
        clearInterval(interval)
        resolve({
          retreaverNumber: window.RetreaverNumber.get("number"),
          retreaverNumberFormatted: window.RetreaverNumber.get("formatted_number")
        })
      }
    }, 5)
  })
}

export const isNameGibberish = (name) => {
  //checking if name has any prefixes or suffixes is highest priority to ignore the name suffix when running name gibberish tests
  name = removePrefixInNameIfExists(name).trim()
  name = removeSuffixInNameIfExists(name).trim()

  //check if name has a special character that connects name ('-') => if name has hyphen, split the str
  //the reason we choose to only remove hyphens is because if we attempt to remove any special character
  //we are defeating the point of checking for name gibberish, if we checked and removed >?!<| etc, we would end up
  //not flagging an incorrect name

  //check if name has a special character that connects name (' ', '-') => if name has hyphen, and replace with empty space string
  name = name.replace(/[-.,]/m, ' ')

  //remove excess white space
  name = name.replace(/\s+/g, ' ').trim()

  const splitName = name.split(' ')

  //remove any initials
  for (let namePart of splitName) {
    if (namePart.length <= 1) {
      splitName.splice(splitName.indexOf(namePart), 1)
    }
  }

  //iterate through array of split names to test individually only if name has hyphen, otherwise proceed with regular check
  return iterateTests(splitName)
}

export const externalDescriptionValidation = async (description) => {
  //hasBadWords and enoughWords will be state values
  const hasBadWords = description.match(PATTERN_BLACK_LIST) ? 'yes' : 'no';
  const enoughWords = description.split(" ").length >= 3 ? 'yes' : 'no';

  let isGibberish = ''

  try {
    const response = await axios.post("https://gibberish.org31415.dev/index.php", {text: description})
    isGibberish = response.data.is_gibberish ? 'yes' : 'no'
  } catch (e) {
  }

  return {
    hasBadWords,
    enoughWords,
    isGibberish
  }
}

const regexNameGibberishTest = (name) => {
  // has less than 2 chars
  if (name.length < 2) {
    return true
  }

  // has no vowels
  if (!/[aeiouyāēīōūȳáéíóúýäöü]/i.test(name)) {
    return true
  }

  // has three consecutive chars
  if (/([A-Za-zÀ-ÖØ-öø-ÿ])\1\1+/.test(name)) {
    return true
  }

  // no vowels in 5 chars
  if (/[^aeiouyāēīōūȳáéíóúýäöü]{5}/mi.test(name)) {
    return true
  }
  return false
}

const removePrefixInNameIfExists = (name) => {
  //remove any prefix in name
  const prefixRegex = /^(hr|fr|md|dr|mr|mrs|miss|ms|sir|sr)(\.|\s+)/i
  if (prefixRegex.test(name)) {
    return name.replace(prefixRegex, "").trim()
  }
  return name
}

const removeSuffixInNameIfExists = (name) => {
  //remove suffix in name
  const suffixRegex = /(?:,|-|\s+)(?:i|ii|iii|iv|jr|sr|dds|phd|md|dvm)\.?$/i
  if (suffixRegex.test(name)) {
    return name.replace(suffixRegex, "").trim()
  }
  return name
}

const iterateTests = (nameArray) => {
  for (const name of nameArray) {
    const regexNameGibberish = regexNameGibberishTest(name)
    if (regexNameGibberish) return true
  }
  return false
}

export const twilioPhonePost = async (phone) => {
  const url = 'https://proxy.leadprosper.tech/two-step-verification/phone'
  let response = {}

  try {
    const respFromReq = await axios.post(url, phone)
    response = respFromReq.data

  } catch (err) {
    console.log(err)
    return { sent: false }
  }


  return response
}

export const twilioCodePost = async (code) => {
  const url = 'https://proxy.leadprosper.tech/two-step-verification/code'
  let response = false

  try {
    const respFromReq = await axios.post(url, code)
    response = respFromReq.data.status === 'approved'
  } catch (err) {
    console.log(err)
  }

  return response
}