<script setup>
import { onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import { getRetreaverNumber } from '@/js/utils'

const store = useStore()
const state = reactive({
  retreaverNumber: '+18773749988',
  retreaverNumberFormatted: '(877) 374-9988'
})

onBeforeMount(() => {
  getRetreaverNumber()
    .then(({retreaverNumber, retreaverNumberFormatted}) => {
      state.retreaverNumber = retreaverNumber
      state.retreaverNumberFormatted = retreaverNumberFormatted
    })
})
</script>

<template>
  <div class="nav-container">
    <div class="nav-wrapper">
      <a class="dbr-logo" href="/">
        <img
          src="../assets/images/DebtReductionTeam_Logo_300x47.png"
          alt="Debt reduction team logo"
        />
        <p>and Other Debt Relief Services</p>
      </a>

      <a class="retreaver-number" :href="`tel:${state.retreaverNumber}`">{{ state.retreaverNumberFormatted }}</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.nav-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 76px;
  width: 100%;
  max-width: 1200px;
  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .dbr-logo {
      margin: 0 10px 0 20px;
    }

    .retreaver-number {
      padding: 8px;
      background-color: #03adb7;
      border-radius: 2px;
      max-width: 170px;
      width: 100%;
      color: #f5f6f6;
      font-size: 18px;
      font-family: "Libre Franklin", sans-serif;
      text-align: center;
      margin: 0 20px 0 10px;
      &:hover {
        background-color: #169eaa;
        cursor: pointer;
      }
    }
  }
  a {
    text-decoration: none;
  }
  img {
    max-width: 300px;
    width: 100%;
  }
  hr {
    width: 100%;
  }
  p {
    text-align: center;
    font-family: sans-serif;
    color: #797979;
    margin: -18px 0 0 48px;
    font-weight: 600;
    font-size: 13px;
  }
}

@media screen and (max-width: 560px) {
  .nav-container {
    .nav-wrapper {
      flex-direction: column;
      .dbr-logo {
        margin: 10px;
      }
      .retreaver-number {
        margin: 10px;
      }
    }
  }
}
</style>
