export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PARTNERS = [
  "Accredited Debt Relief",
  "Debt Relief 123",
  "Debt.org",
  "National Debt Relief",
  "Pacific Debt",
  "USA vs. Debt",
]

export const hasBadWords = () => {
  const badWordsList = [
    "Anitadick",
    "Ass",
    "Asshead",
    "Asswhipe",
    "Bad",
    "Bitch",
    "Boner",
    "Cockboy",
    "Cockman",
    "Cum",
    "Cunt",
    "Dick",
    "Dickeater",
    "Dickhead",
    "Diddlier",
    "Dog",
    "Donkey",
    "Dumb",
    "Erec ",
    "Fat",
    "Fatass",
    "Fuck",
    "Fucked",
    "Fucker",
    "Fuckhead",
    "Fucktard",
    "Good",
    "He",
    "Her",
    "His",
    "HughJass",
    "huge",
    "I",
    "Ip",
    "Ipe",
    "Ipee",
    "Jackass",
    "Jackle",
    "Jerk",
    "Jerker",
    "Jizz",
    "King",
    "Large",
    "Midget",
    "Murder",
    "No",
    "Obese",
    "Our",
    "Ours",
    "Penis",
    "piss",
    "Prick",
    "PrickDick",
    "Rape",
    "She",
    "Shit",
    "Stupid",
    "Tits",
    "Tion ",
    "Torture",
    "Troll",
    "Vagina",
    "What",
    "When",
    "Who",
    "Why",
    "Yes",
    "You",
    "Big",
    "Bigdick",
    "Semen",
    "hentai",
    "spongebob",
    "squarepants",
    "faggot",
    "pos",
    "fag",
    "puto",
    "madre",
    "nigger",
    "nigga",
    "nig",
    "ger",
    "cracker",
    "blowjob",
    "blow",
    "hardon",
    "eat",
    "rimjob",
    "fuckyou",
    "asswipe",
    "cock",
    "sex",
    "sexy",
    "fisting",
    "dildo",
    "bluewaffle",
    "pedophile",
    "pedo",
    "sexual",
    "asshole",
    "meoff",
    "jackmeoff",
    "niger",
  ];
  return new RegExp(`^(${badWordsList.join("|").toLowerCase()})$`,"ig");
}
