<script setup>
import { useStore } from 'vuex'
import {computed, reactive, watch} from "vue";

const store = useStore()

const state = reactive({
  verificationCode: '',
  showErrorMsg: false,
  showModal: computed(() => store.state.twilioSmsVerify.toggleModalWithSmsVerification),
  resendCodeCoolDown: computed(() => store.state.twilioSmsVerify.resendCodeCoolDown),
  sentPhoneVerificationCode: computed( () => store.state.twilioSmsVerify.twilio.sentStatus),
  phoneTwilioVerifiedAndApproved: computed(() => store.state.twilioSmsVerify.approved),
  authenticationLoading: computed(() => store.state.twilioSmsVerify.authenticationLoading)
})

watch(() => [state.verificationCode], () => {
  if (state.verificationCode.length > 0) state.showErrorMsg = false
})

const closeModal = () => {
  document.body.classList.remove('modal-open')
  store.commit('twilioSmsVerify/TOGGLE_MODAL_WITH_SMS_VERIFICATION')
}

const resendCode = () => {
  store.dispatch('twilioSmsVerify/resendCode')
}

const verify = async () => {
  if (state.verificationCode.length < 6) {
    state.showErrorMsg = true
    return
  }

  store.commit('twilioSmsVerify/SET_CODE', state.verificationCode)

  await store.dispatch('twilioSmsVerify/postCode')

  if (state.phoneTwilioVerifiedAndApproved) return

  state.showErrorMsg = true
}
</script>

<template>
  <div class="twilio-modal">
    <div class="twilio-container">
      <img
          src="../assets/images/close.svg"
          class="close-modal"
          alt="x symbol"
          @click="closeModal"
      >
      <div class="twilio-wrapper">
        <h2 class="twilio-verify-header">
          <img class="sms-svg" src="../assets/images/sms.svg" alt="sms-icon">
          Verification code
        </h2>
        <p class="twilio-verify-subheader">
          Enter your verification code that we sent to
          <span class="twilio-phone">{{ store.state.twilioSmsVerify.rawPhone }}</span>
        </p>

        <input
          v-model="state.verificationCode"
          :class="{'border-error': state.showErrorMsg}"
          type="text"
          id="twilio-verify"
          class="form-field twilio-input"
          v-maska="'######'"
          placeholder="000000"
        />
        <div class="error-msg twilio-failed-verification">
          <span
            v-if="state.showErrorMsg"
          >
            Verification failed, please enter the code you've received
          </span>
        </div>
        <div class="resend-verification">
          <span
            @click="resendCode"
            :class="{'disabled-resend-twilio' : state.resendCodeCoolDown}"
          >
            {{ state.resendCodeCoolDown ? 'New code has been sent!' : `Didn't get the code? Send a new one` }}
          </span>
        </div>

        <div class="button-block">
          <button
            @click="verify"
            :class="{ 'btn-disabled': state.authenticationLoading }"
            :disabled="state.authenticationLoading"
            class="question-button small-question-button"
          >
            {{ state.authenticationLoading ? 'Verifying...' : 'Verify' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.twilio-modal {
  background-color: rgba(0,0,0,.4);
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  font-family: "Poppins", sans-serif;
  .twilio-container {
    align-items: center;
    background-color: #fefefe;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    margin: auto;
    .close-modal {
      margin-left: auto;
      height: 25px;
      width: 25px;
      &:hover {
        cursor: pointer;
      }
    }
    .twilio-wrapper {
      width: 80%;
      .twilio-input {
        font-size: 18px;
        color: #666666;
        font-family: "Libre Franklin", sans-serif;
        background-color: #fff;
        border: 1px solid #cccccc;
        border-radius: 5px;
        padding: 9px 15px;
        text-overflow: ellipsis;
        text-align: left;
        margin: 0 auto;
        min-height: 30px;
        width: calc(100% - 32px);
        &.border-error {
          border: 1px solid red;
        }
      }
      .twilio-failed-verification {
        color: #ff0014;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        margin-top: 5px;
        min-height: 21px;
      }
      .resend-verification {
        color: #1eabb8;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        &:hover {
          color: #169eaa;
        }
        .disabled-resend-twilio {
          color: #00283f94;
          cursor: not-allowed;
        }
      }
      .button-block {
        text-align: center;
        .question-button {
          background: #1eabb8;
          text-align: center;
          float: left;
          padding: 10px 20px;
          width: 100%;
          height: auto;
          text-decoration: none;
          font-size: 20px;
          line-height: 26px;
          color: #fff;
          font-weight: bold;
          font-family: "Libre Franklin", sans-serif;
          text-transform: uppercase;
          margin-top: 15px;
          margin-bottom: 15px;
          border-radius: 50px;
          border: none;
          cursor: pointer;
          &:hover {
            background: #169eaa;
          }
          &.btn-disabled {
            background: #1eabb8;
            opacity: .5;
            cursor: not-allowed;
          }
        }
      }
      .twilio-verify-header {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 12px;
        margin-top: 5px;;
        font-weight: bold;
        font-family: "librefranklin-extrabold", sans-serif;
        font-size: 24px;
        line-height: 36px;
        color: #72ccbf;
        text-transform: uppercase;
        text-align: center;
        text-shadow: 1px 1px #256483;
        .sms-svg {
          height: 35px;
          width: 35px;
          margin-right: 8px;
        }
      }
      .twilio-verify-subheader {
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        margin-bottom: 12px;
        text-align: left;
        .twilio-phone {
          display: inline-block;
          font-weight: 600;
          letter-spacing: 1px;
        }
      }
    }
  }
}

@media (max-width: 481px) {
  .sms-svg {
    display: none;
  }
}
</style>