import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { title: 'Debt Reduction Team' },
    component: Home,
  },
  {
    path: "/v2",
    name: "Home v2",
    meta: { title: 'Debt Reduction Team' },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/v3",
    name: "Home v3",
    meta: { title: 'Debt Reduction Team' },
    component: () => import("../views/HomeV3.vue"),
  },
  // {
  //   path: "/v4",
  //   name: "Home v4",
  //   meta: { title: 'Debt Reduction Team' },
  //   component: () => import("../views/HomeV4.vue"),
  // },
  {
    path: "/california-policy",
    name: "CaliforniaPolicy",
    meta: { title: 'Debt Reduction Team' },
    component: () => import("../views/CaliforniaPrivacy"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    meta: { title: 'Debt Reduction Team' },
    component: () => import("../views/PrivacyPolicy")
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    meta: { title: 'Debt Reduction Team' },
    component: () => import("../views/TermsAndConditions")
  },
  {
    path: "/partners",
    name: "Partners",
    meta: { title: 'Debt Reduction Team' },
    component: () => import("../views/PartnersPage")
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    meta: { title: 'Debt Reduction Team' },
    component: () => import("../views/ThankYou")
  },
  {
    path: "/thank-you-v2",
    name: "ThankYouV2",
    meta: { title: 'Debt Reduction Team' },
    component: () => import("../views/TapStoneThankYou")
  },
  {
    path: "/disqualified",
    name: "Disqualified",
    meta: { title: 'Debt Reduction Team' },
    component: () => import("../views/Disqualify")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: { title: 'Debt Reduction Team' },
    component: () => import("../views/PageNotFound")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
